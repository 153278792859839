table.data-table {
  margin-bottom: 50px;
  /* width: 100%; */
}

table.data-table td {
  font-size: 0.8em;
  padding: 2px;
  margin: 0;
  text-align: right;
  line-height: 1.1em;
  white-space: nowrap;
  font-weight: bold;
}

table.data-table th {
  font-size: 0.9em;
  padding: 2px;
  text-align: right;
  overflow-wrap: break-word;
  font-weight: bold;
}

table.data-table td:first-child,
table.data-table th:first-child {
  text-align: left;
}

@media (min-width: 0px) and (max-width: 767px) {
  table.data-table {
    width: 100%;
  }
}
