@font-face {
  font-family: "Lato";
  src: local("Lato"), url(./fonts/Lato/Lato-Light.ttf) format("truetype");
  font-weight: 500;
}

body {
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: white;
}

* {
  box-sizing: border-box;
}

.amplify-scrollview::-webkit-scrollbar-corner {
  background-color: rgb(0, 0, 0); /* color of the scroll thumb */
  background: black;
}

@media print {
  html,
  body {
    display: none; /* hide whole page */
  }
}
